import CryptoJS from 'crypto-js'

export const deserialize = input => {
	try {
		return JSON.parse(CryptoJS.AES.decrypt(input, "Dw{WRP?:E$^j5a*@").toString(CryptoJS.enc.Utf8))
	} catch (error) {
		return {}
	}
}

export const serialize = input => {
	return CryptoJS.AES.encrypt(JSON.stringify(input), "Dw{WRP?:E$^j5a*@")
}
