import Vue from 'vue'
import VueRouter from 'vue-router'
import pinia from '@/store'
import { useAppState } from '@/store/AppState'

const routes = [
	{
		path: '/',
		component: () => import('@/views/WebHomeIndex'),
		children: [
			{
				path: '/',
				component: () => import('@/components/home/HomeIndex'),
			},
			{
				path: '/login',
				component: () => import('@/components/home/HomeLogin'),
			},

			// {
			// 	path: '/medali',
			// 	name: 'medali',
			// 	component: () => import('@/components/home/HomeMedali'),
			// },
			// {
			// 	path: '/data',
			// 	component: () => import('@/components/home/HomeData'),
			// },
			// {
			// 	path: '/join',
			// 	component: () => import('@/components/home/HomeJoin'),
			// },
		]
	},
	{
		path: '/admin',
		component: () => import('@/views/WebAdminIndex'),
		meta: {
			levelGranted: ['D', 'S', 'A'],
		},
		children: [
			{
				path: '/admin',
				name: 'data',
				component: () => import('@/components/admin/AdminPertandingan'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/account',
				name: 'account',
				component: () => import('@/components/admin/AdminAccount'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/auditLog',
				name: 'auditLog',
				component: () => import('@/components/admin/AdminAuditLog'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
		]
	},
	{
		path: '*',
		component: () => import( '@/views/WebNotFound'),
	},
]

Vue.use(VueRouter)

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach(async (to, from, next) => {
	let nextOrDefault = (condt, def) => condt ? next() : next(def)
	let lRoutes = to.matched.filter(record => record.meta.levelGranted)

	if (lRoutes.length > 0) {
		const store = useAppState(pinia)
		await store.checkLogin()

		let isGranted = false

		if (store.level_user) isGranted = lRoutes[0].meta.levelGranted.includes(store.level_user)

		nextOrDefault(isGranted, '/login')
	} else {
		next()
	}
})

export default router
